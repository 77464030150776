// assets
import {
  IconFish,
  IconLicense,
  IconMoneybag,
  IconFileInvoice,
  IconTrolley,
  IconCashOff,
  IconReport,
  IconShoppingCart,
  IconCoins,
  IconChartPie,
  IconBuildingSkyscraper,
} from '@tabler/icons-react';

// constant
const icons = {
  IconFish,
  IconLicense,
  IconMoneybag,
  IconFileInvoice,
  IconTrolley,
  IconCashOff,
  IconReport,
  IconShoppingCart,
  IconCoins,
  IconChartPie,
  IconBuildingSkyscraper,
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const pages = {
  id: 'pages',
  title: 'Pages',
  type: 'group',
  children: [
    {
      id: 'project-product',
      title: 'Project and Product',
      type: 'collapse',
      color: '#2F0F5D',
      icon: icons.IconFish,
      children: [
        {
          id: 'land-owner',
          title: 'Land Owner',
          type: 'item',
          url: '/pages/project-product/land-owner',
        },
        {
          id: 'project',
          title: 'Projects',
          type: 'item',
          url: '/pages/project-product/project',
        },
        {
          id: 'uom',
          title: 'Unit of Measurement',
          type: 'item',
          url: '/pages/project-product/uom',
        },
        {
          id: 'product',
          title: 'Products',
          type: 'item',
          url: '/pages/project-product/product',
        },
      ],
    },
    {
      id: 'office-management',
      title: 'Office Management',
      type: 'collapse',
      color: '#2F0F5D',
      icon: icons.IconBuildingSkyscraper,
      children: [
        {
          id: 'employees',
          title: 'Employees',
          type: 'item',
          url: '/pages/office-management/employees',
        },
        {
          id: 'salaries',
          title: 'Salaries',
          type: 'item',
          url: '/pages/office-management/salaries',
        },
        {
          id: 'utility-bill',
          title: 'Utility Bill',
          type: 'item',
          url: '/pages/office-management/utility-bill',
        },
      ],
    },
    {
      id: 'expense-management',
      title: 'Expense Management',
      type: 'collapse',
      color: '#2F0F5D',
      icon: icons.IconLicense,
      children: [
        {
          id: 'vendors',
          title: 'Vendors',
          type: 'item',
          url: '/pages/expense-management/vendors',
        },
        {
          id: 'expense-heads',
          title: 'Expense Heads',
          type: 'item',
          url: '/pages/expense-management/expense-heads',
        },
        {
          id: 'operation-expense',
          title: 'Operation Expenses',
          type: 'item',
          url: '/pages/expense-management/operation-expense',
        },
        {
          id: 'production-expense',
          title: 'Production Expenses',
          type: 'item',
          url: '/pages/expense-management/production-expense',
        },
      ],
    },
    {
      id: 'purchase-management',
      title: 'Purchase Management',
      type: 'collapse',
      color: '#2F0F5D',
      icon: icons.IconFileInvoice,
      children: [
        {
          id: 'purchase',
          title: 'Purchases',
          type: 'item',
          url: '/pages/purchase-management/purchase',
        },
        {
          id: 'purchase-summary',
          title: 'Purchase Summary',
          type: 'item',
          url: '/pages/purchase-management/purchase-summary',
        },
      ],
    },
    {
      id: 'income-management',
      title: 'Income Management',
      type: 'collapse',
      color: '#2F0F5D',
      icon: icons.IconShoppingCart,
      children: [
        {
          id: 'customers',
          title: 'Customers',
          type: 'item',
          url: '/pages/income-management/customers',
        },
        {
          id: 'income-heads',
          title: 'Income Heads',
          type: 'item',
          url: '/pages/income-management/income-heads',
        },
        {
          id: 'incomes',
          title: 'Incomes',
          type: 'item',
          url: '/pages/income-management/incomes',
        },
      ],
    },
    {
      id: 'financial',
      title: 'Financial',
      type: 'collapse',
      color: '#2F0F5D',
      icon: icons.IconMoneybag,
      children: [
        {
          id: 'receive-payment',
          title: 'Receive a Payment',
          type: 'item',
          url: '/pages/financial/receive-payment',
        },
        {
          id: 'make-payment',
          title: 'Make a Payment',
          type: 'item',
          url: '/pages/financial/make-payment',
        },
        {
          id: 'fixed-asset',
          title: 'Fixed Asset',
          type: 'item',
          url: '/pages/financial/fixed-asset',
        },
        {
          id: 'investment',
          title: 'Investment',
          type: 'item',
          url: '/pages/financial/investment',
        },
        {
          id: 'withdraw',
          title: 'Withdraw',
          type: 'item',
          url: '/pages/financial/withdraw',
        },
        {
          id: 'account-summary',
          title: 'Account Summary',
          type: 'item',
          url: '/pages/financial/account-summary',
        },
      ],
    },
    {
      id: 'analysis-costing',
      title: 'Analysis & Costing',
      type: 'collapse',
      color: '#2F0F5D',
      icon: icons.IconChartPie,
      children: [
        {
          id: 'analysis-list',
          title: 'Analysis List',
          type: 'item',
          url: '/pages/analysis-costing/analysis-list',
        },
        {
          id: 'fish-size',
          title: 'Fish Size',
          type: 'item',
          url: '/pages/analysis-costing/fish-size',
        },
        {
          id: 'analysis-summary',
          title: 'Analysis Summary',
          type: 'item',
          url: '/pages/analysis-costing/analysis-summary',
        },
      ],
    },
    {
      id: 'report',
      title: 'Report',
      type: 'collapse',
      color: '#2F0F5D',
      icon: icons.IconReport,
      children: [
        {
          id: 'summary',
          title: 'Summary',
          type: 'item',
          url: '/pages/report/summary',
        },
        {
          id: 'due-report',
          title: 'Due Report',
          type: 'item',
          url: '/pages/report/due-report',
        },
        {
          id: 'expense-summary',
          title: 'Expense Summary',
          type: 'item',
          url: '/pages/report/expense-summary',
        },
      ],
    },
  ],
};

export default pages;
